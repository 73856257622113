import { useState, useEffect, useCallback } from "react";

interface MapState {
	nodes: any[];
	edges: any[];
	timestamp: number;
}

export const useUndoRedo = (maxTimes: number = 50) => {
	const [history, setHistory] = useState<MapState[]>([]);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [db, setDb] = useState<IDBDatabase | null>(null);

	// Initialize IndexedDB
	useEffect(() => {
		const request = indexedDB.open("mapHistoryDB", 1);

		request.onerror = () => {
			console.error("IndexedDB error:", request.error);
		};

		request.onsuccess = () => {
			console.log("IndexedDB opened successfully");
			setDb(request.result);
		};

		request.onupgradeneeded = (event) => {
			const db = (event.target as IDBOpenDBRequest).result;
			if (!db.objectStoreNames.contains("mapHistory")) {
				db.createObjectStore("mapHistory", { keyPath: "timestamp" });
			}
		};

		return () => {
			if (db) {
				db.close();
			}
		};
	}, []);

	const getStoreCount = async (store: IDBObjectStore): Promise<number> => {
		return new Promise((resolve, reject) => {
			const countRequest = store.count();
			countRequest.onsuccess = () => resolve(countRequest.result);
			countRequest.onerror = () => reject(countRequest.error);
		});
	};

	// Save state to both memory and IndexedDB
	const saveState = useCallback(
		async (nodes: any[], edges: any[]) => {
			try {
				const newState: MapState = {
					nodes: JSON.parse(JSON.stringify(nodes)),
					edges: JSON.parse(JSON.stringify(edges)),
					timestamp: Date.now(),
				};

				setHistory((prev) => {
					const newHistory = [...prev.slice(0, currentIndex + 1), newState].slice(-maxTimes);
					return newHistory;
				});

				// Ensure the index stays within bounds
				setCurrentIndex((prev) => {
					const newIndex = Math.min(prev + 1, maxTimes - 1);
					console.log("Setting new index:", newIndex);
					return newIndex;
				});

				// Save to IndexedDB
				if (db) {
					const transaction = db.transaction(["mapHistory"], "readwrite");
					const store = transaction.objectStore("mapHistory");

					// Clear old entries if we're at capacity
					const count = await getStoreCount(store);

					if (count >= maxTimes) {
						const cursorRequest = store.openCursor();
						cursorRequest.onsuccess = (event) => {
							const cursor = (event.target as IDBRequest<IDBCursorWithValue | null>).result;
							if (cursor) {
								cursor.delete();
								cursor.continue();
							}
						};
					}

					// Add new state
					const addRequest = store.add(newState);
					addRequest.onsuccess = () => {
						console.log("State saved to IndexedDB");
					};
					addRequest.onerror = () => {
						console.error("Error saving state to IndexedDB:", addRequest.error);
					};
				}

				console.log("State saved successfully");
			} catch (error) {
				console.error("Error saving state:", error);
			}
		},
		[db, currentIndex, maxTimes]
	);

	const undo = useCallback(
		(setNodes: Function, setEdges: Function) => {
			try {
				// console.log('Current Index:', currentIndex);
				// console.log('History Length:', history.length);

				// Add additional validation
				if (currentIndex <= 0 || currentIndex >= history.length) {
					//   console.log("Invalid undo state:", { currentIndex, historyLength: history.length });
					// Reset the index if it's invalid
					setCurrentIndex(Math.min(history.length - 1, 0));
					return;
				}

				const previousState = history[currentIndex - 1];
				if (!previousState) {
					console.log("No previous state found at index:", currentIndex - 1);
					return;
				}

				setNodes(previousState.nodes);
				setEdges(previousState.edges);
				setCurrentIndex((prev) => prev - 1);
				console.log("Undo successful");
			} catch (error) {
				console.error("Error during undo:", error);
			}
		},
		[history, currentIndex]
	);

	const redo = useCallback(
		(setNodes: Function, setEdges: Function) => {
			try {
				if (currentIndex >= history.length - 1) {
					console.log("Nothing to redo");
					return;
				}

				const nextState = history[currentIndex + 1];
				if (nextState) {
					setNodes(nextState.nodes);
					setEdges(nextState.edges);
					setCurrentIndex((prev) => prev + 1);
					console.log("Redo successful");
				}
			} catch (error) {
				console.error("Error during redo:", error);
			}
		},
		[history, currentIndex]
	);

	return {
		saveState,
		undo,
		redo,
		canUndo: currentIndex > 0,
		canRedo: currentIndex < history.length - 1,
		currentIndex: currentIndex,
	};
};
