import React, { useEffect, useState, useCallback, useRef } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import './colorPicker.css';
import moveArrows from '../../../assets/icons/arrowsMove.svg';
import { Trans } from 'react-i18next';

interface ColorPickerProps {
  onColorConfirm: (color: string) => void;
  activeElement: HTMLElement | null;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onColorConfirm, activeElement }) => {
  const [red, setRed] = useState(0);
  const [green, setGreen] = useState(0);
  const [blue, setBlue] = useState(0);
  const [position, setPosition] = useState({ top: 50, left: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const pickerRef = useRef<HTMLDivElement>(null);
  const selectionRef = useRef<Range | null>(null);

  const hexColor = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;

  // Store selection when component mounts or activeElement changes
  useEffect(() => {
    const selection = window.getSelection();
    if (selection && !selection.isCollapsed) {
      selectionRef.current = selection.getRangeAt(0).cloneRange();
    }
  }, [activeElement]);

  const handleConfirm = useCallback(() => {
    if (selectionRef.current && activeElement) {
      const selection = window.getSelection();
      if (selection) {
        // Focus the active element first
        activeElement.focus();
        
        // Small delay to ensure focus is established
        requestAnimationFrame(() => {
          selection.removeAllRanges();
          selection.addRange(selectionRef.current!);
          onColorConfirm(hexColor);
        });
      }
    } else {
      onColorConfirm(hexColor);
    }
  }, [hexColor, onColorConfirm, activeElement]);

  const handleDragStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    if ('touches' in e) {
      // For touch events
      const touch = e.touches[0];
      setDragStart({
        x: touch.clientX - position.left,
        y: touch.clientY - position.top
      });
    } else {
      // For mouse events
      setDragStart({
        x: e.clientX - position.left,
        y: e.clientY - position.top
      });
    }
    setIsDragging(true);
  }, [position.left, position.top]);

  const handleDragMove = useCallback((e: MouseEvent | TouchEvent) => {
    if (!isDragging) return;

    let clientX: number;
    let clientY: number;

    if ('touches' in e) {
      const touch = e.touches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    } else {
      clientX = (e as MouseEvent).clientX;
      clientY = (e as MouseEvent).clientY;
    }

    const newLeft = clientX - dragStart.x;
    const newTop = clientY - dragStart.y;

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Get picker dimensions
    const pickerRect = pickerRef.current?.getBoundingClientRect();
    const pickerWidth = pickerRect?.width || 0;
    const pickerHeight = pickerRect?.height || 0;

    // Constrain to viewport
    const constrainedLeft = Math.max(0, Math.min(newLeft, viewportWidth - pickerWidth));
    const constrainedTop = Math.max(0, Math.min(newTop, viewportHeight - pickerHeight));

    setPosition({
      left: constrainedLeft,
      top: constrainedTop
    });
  }, [isDragging, dragStart]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (!isDragging) return;

    // Setup move handlers
    const moveHandler = (e: TouchEvent | MouseEvent) => {
      e.stopPropagation();
      handleDragMove(e);
    };

    // Setup end handlers
    const endHandler = () => {
      handleDragEnd();
    };

    // Add event listeners with proper options
    if ('ontouchstart' in window) {
      document.addEventListener('touchmove', moveHandler, { passive: true });
      document.addEventListener('touchend', endHandler);
      document.addEventListener('touchcancel', endHandler);
    } else {
      document.addEventListener('mousemove', moveHandler);
      document.addEventListener('mouseup', endHandler);
    }

    // Cleanup
    return () => {
      if ('ontouchstart' in window) {
        document.removeEventListener('touchmove', moveHandler);
        document.removeEventListener('touchend', endHandler);
        document.removeEventListener('touchcancel', endHandler);
      } else {
        document.removeEventListener('mousemove', moveHandler);
        document.removeEventListener('mouseup', endHandler);
      }
    };
  }, [isDragging, handleDragMove, handleDragEnd]);

  return (
    <div 
      ref={pickerRef}
      className="popup"
      style={{
        position: 'fixed',
        top: position.top,
        left: position.left,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        touchAction: 'pan-x pan-y'
      }}
    >
      <div 
        className="move-handle"
        onTouchStart={handleDragStart}
        onMouseDown={handleDragStart}
      >
        <IonIcon icon={moveArrows} className="move-btn" />
      </div>
      <div className="color-sliders">
        <ColorSlider color="Red" value={red} onChange={setRed} />
        <ColorSlider color="Green" value={green} onChange={setGreen} />
        <ColorSlider color="Blue" value={blue} onChange={setBlue} />
      </div>
      <div className="mt-4 flex items-center">
        <div
          className="w-16 h-16 rounded-md"
          style={{ backgroundColor: hexColor }}
        >
          <span className="font-mono">{hexColor}</span>
        </div>
      </div>
      <IonButton 
        onClick={handleConfirm}
        className="mt-4 w-full summariesColorPicker"
      >
        <Trans>Conferma colore</Trans>
      </IonButton>
    </div>
  );
};

// ColorSlider component
interface ColorSliderProps {
  color: string;
  value: number;
  onChange: (value: number) => void;
}

const ColorSlider: React.FC<ColorSliderProps> = ({ color, value, onChange }) => {
  const sliderClass = `w-full ${color.toLowerCase()}-slider`;
  
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      onChange(newValue);
    }
  }, [onChange]);

  return (
    <div className="flex items-center mb-2">
      <label className="w-10 mr-2">{color}</label>
      <input
        type="range"
        min="0"
        max="255"
        value={value}
        onChange={handleChange}
        className={sliderClass}
      />
      <span className="w-10 ml-2 text-right">{value}</span>
    </div>
  );
};

export default ColorPicker;